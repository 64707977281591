import { Button, Container, Stack, Typography } from '@mui/material';
import IntroHead from '#/components/shared/IntroHead';
import useLocales from '#/hooks/useLocales';
import TrafficLights from '#/components/pages/PensionProvision/onboarding/steps/profiling/traffic-lights';
import useAuth from '#/hooks/useAuth';
import RedLightRecommendations from '#/components/pages/PensionProvision/onboarding/steps/profiling/recommendations/red-light-recommendations';
import GreenLightRecommendations from './recommendations/green-light-recommendations';
import YellowLightRecommendations from './recommendations/yellow-light-recommendations';
import StepsIndicators from '../registration/steps-indicator';
import { useNavigate } from 'react-router-dom';

const TRAFFIC_LABELS = [
  'pensionProvision.onboarding.stepThree.redLightLabel',
  'pensionProvision.onboarding.stepThree.yellowLightLabel',
  'pensionProvision.onboarding.stepThree.greenLightLabel',
];

export default function StepThree() {
  const { translate } = useLocales();
  const { user } = useAuth();
  const navigate = useNavigate();

  const getTrafficLightState = () => {
    const provisionCount = user?.provision_check_done?.length ?? 0;
    if (provisionCount === 0) return 0;
    if (provisionCount < 6) return 1;
    return 2;
  };

  const isActive = getTrafficLightState();

  const isRedRecommendation = getTrafficLightState() === 0;
  const isYellowRecommendation = getTrafficLightState() === 1;
  const isGreenRecommendation = getTrafficLightState() === 2;

  return (
    <Container maxWidth="lg" sx={{ marginY: 12 }}>
      <Stack direction="row" justifyContent="center" alignItems="center">
        <Button color="primary" onClick={() => navigate('/vorsorge/schritt=2')}>
          <Typography>{translate('global.back')}</Typography>
        </Button>
      </Stack>
      <IntroHead
        title={String(translate('pensionProvision.onboarding.stepTwo.title'))}
        description=""
      />
      <StepsIndicators activeStep={3} completedSteps={[1, 2]} />

      <Typography variant="subtitle1" fontWeight="normal" textAlign="center">
        {String(translate('pensionProvision.onboarding.stepThree.stepByStep'))}
      </Typography>
      <TrafficLights isActive={isActive} />
      <Typography variant="subtitle1" textAlign="center">
        {String(translate(TRAFFIC_LABELS[isActive]))}
      </Typography>
      {isRedRecommendation && <RedLightRecommendations />}
      {isYellowRecommendation && <YellowLightRecommendations />}
      {isGreenRecommendation && <GreenLightRecommendations />}
    </Container>
  );
}
